import React from "react";

const NotAccessible = () => {
  return (
    <div
      style={{
        fontSize: "30px",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height:"85vh"
      }}
    >
      <div>Not Accessible</div>
    </div>
  );
};

export default NotAccessible;
