export const numberingWizardHeadingData = [
  { text: "Fill up your details and proceed next steps." },
];

export const stepperHorizontalData = [
  "Basic Info",
  "Cart Info",
  "Feedback",
  "Finish",
];

export const studentValidationFormHeadingData = [
  { text: "Please make sure fill all the filed before click on next button." },
];
export const verticalValidationWizardHeadingData = [
  { text: "Fill up your true details and next proceed." },
];

export const verticalValidationWizardNavData = [
  {
    iconClassName: "fa-user",
    tittle: "Your Info",
    tittleInforMation: "Add your details ",
  },
  {
    iconClassName: "fa-chain-broken",
    tittle: "Cart Info",
    tittleInforMation: "Add your a/c details",
  },
  {
    iconClassName: "fa-group",
    tittle: "Net Banking",
    tittleInforMation: "Choose your bank",
  },
];

export const netBankingFormBankList = [
  {
    bankList: [
      "Industrial & Commercial Bank",
      "Agricultural Bank",
      "JPMorgan Chase & Co.",
    ],
  },
  { bankList: ["Construction Bank Corp.", "Bank of America", "HDFC Bank"] },
];

export const shippingFormHeadingData = [
  { text: "Fill up your true details and next proceed." },
];

export const shipIngNavFormData = [
  {
    activeTab: 1,
    iconClassName: "fa-user",
    tittle: "Billing",
  },
  {
    activeTab: 2,
    iconClassName: "fa-truck",
    tittle: "Shipping",
  },
  {
    activeTab: 3,
    iconClassName: "fa-money",
    tittle: "Payment",
  },
  {
    iconClassName: "fa-check-square",
    tittle: "Finish",
  },
];

export const homeAndOfficeAddressData = [
  {
    value: "shipping-choose1",
    label: "Home",
    name: "Filomena Green ",
    adress: "2211 Fruitville Rd, Sarasota, Florida, US 34237",
    contact: "(941) 321-5643",
  },
  {
    value: "shipping-choose2",
    label: "Work",
    name: "Ms. Ila Runte DDS",
    adress: " 1531 E 23rd St S, Independence, Mississippi, US 64055",
    contact: "(816) 252-4500",
  },
];

export const shippingMethodsData = [
  {
    value: "standardDelivery",
    label: "Standard Delivery - Free",
    details: "Estimated 5-7 days shipping",
  },
  {
    value: "expressDelivery",
    label: "Express Delivery - $24",
    details: "Estimated 1-2 days shipping",
  },
];

export let currentCartTableBodyData = [
  {
    imagePath: "product/13.png",
    productName: "T-shirt",
    productSum: "$98 * 2",
    price: "$400",
  },
  {
    imagePath: "email-template/4.png",
    productName: "Headphone",
    productSum: "$4*2",
    price: "$450",
  },
  {
    imagePath: "product/2.png",
    productName: "T-shirt ",
    productSum: "$10*2",
    price: "$234",
  },
  {
    imagePath: "dashboard-2/product/2.png",
    productName: "Chairs ",
    productSum: "$98*2",
    price: "$200",
  },
];

export let currentCartTableFooterData = [
  { footerTittle: "Sub Total :", price: "1284.00" },
  { footerTittle: "Discount :", price: "20.00" },
  { footerTittle: "Shipping Charge :", price: "100.78" },
  { footerTittle: "Tax :", price: "205.34" },
  { footerTittle: "Total (USD) :", price: "1569.7" },
];

export const modalOneData = [
  {
    iconClassName: "fa-spin fa-cog",
    tittle: "2FA Authenticator",
    description: "Obtain codes from a authy/google authenticator/ios 15 etc.",
  },
  {
    iconClassName: "fa-comments",
    tittle: "SMS",
    description:
      "The backup login method will be sent to you via SMS if you require it.",
  },
];

export const numbers = [1, 2, 3, 4, 5, 6];

export const customHorizontalWizardNavData = [
  { activeTab: 1, iconName: "fa-user", tittle: "Server info" },
  { activeTab: 2, iconName: "fa-chain-broken", tittle: "Account Credentials" },
  { activeTab: 3, iconName: "fa-group", tittle: "Server Credentials" },
  { activeTab: 4, iconName: "fa-chain-broken", tittle: "CRM Modules" },
  { activeTab: 5, iconName: "fa-group", tittle: "Attachements" },
  { iconName: "fa-group", tittle: "Completed" },
];

export const popularBanksList = [
  { imageName: "hdfc.png", bankName: "ABC BANK" },
  { imageName: "Bank-of-Baroda.png", bankName: "XYZ BANK" },
  { imageName: "idbi.png", bankName: "PQR BANK" },
  { imageName: "rbl.png", bankName: "DEF BANK" },
  { imageName: "us-bank.png", bankName: "MNO BANK" },
  { imageName: "axis.png", bankName: "WXY BANK" },
  { imageName: "SCB.png", bankName: "STD BANK" },
];

export const itemsName = [
  "Featured Items",
  "Newsletters",
  "Notifications",
  "Blogs",
];

export const businessVerticalWizardFormData = [
  { activeTab: 1, tittle: "Choose account" },
  { activeTab: 2, tittle: "Business settings" },
  { activeTab: 3, tittle: "Contact details" },
  { activeTab: 4, tittle: "Pay details" },
  { tittle: "completed" },
];

export const selectAccountBankFormData = [
  { iconName: "fa-university", tittle: "Business" },
  { iconName: "fa-user", tittle: "Personal " },
];

export const variationBoxData = [
  { tittle: "Tivo", details: "2-3 Members" },
  { tittle: "Cion", details: "4-5 Members" },
  { tittle: "Multikart", details: "2 Member" },
  { tittle: "Roxo", details: "1 Member" },
];

export const listOfYears: number[] = [
  2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035,
];
