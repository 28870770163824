

import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import LocationArrow from "../../../../../assets/deviceLocationArrow.png";

const Location = (props) => {
  const { createComplainModalData } = useSelector((state) => state.complainCenter);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const mapRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleMarkerClick = (props, marker) => {
    // console.log("marker", marker);
    setActiveMarker(marker);
    setShowInfoWindow(true);
  };

  useEffect(() => {
    if (createComplainModalData && createComplainModalData.vehicleInfo) {
      handleMarkerClick();
    }
  }, [createComplainModalData]);

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Load the static image
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = LocationArrow;

    img.onload = () => {
      // Calculate canvas dimensions to accommodate rotated image
      const canvasWidth = Math.max(img.width, img.height);
      const canvasHeight = canvasWidth;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      // Clear canvas
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Rotate and draw image
      context.translate(canvas.width / 2, canvas.height / 2);

      context.rotate((createComplainModalData.vehicleInfo.course * Math.PI) / 180);
      context.drawImage(
        img,
        -img.width / 2,
        -img.height / 2,
        img.width,
        img.height
      );

      // Convert canvas to data URL
      const rotatedIconUrl = canvas.toDataURL();

      const markerInstance = new window.google.maps.Marker({
        position: {
          lat: parseFloat(createComplainModalData.vehicleInfo.lat),
          lng: parseFloat(createComplainModalData.vehicleInfo.lng),
        },
        map: mapRef.current.map,
        icon: {
          url: rotatedIconUrl,
          scaledSize: new window.google.maps.Size(canvasWidth, canvasHeight),
        },
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div>
        <h3>Device Info</h3>
        <br />
       
        <p>
          <span style={{ fontWeight: "bold" }}>Device Name: </span>
          <span>${createComplainModalData?.vehicleInfo?.deviceName}</span>
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>LatLng: </span>
          <a href="https://www.google.com/maps/search/?api=1&query=${createComplainModalData?.vehicleInfo?.lat},${createComplainModalData?.vehicleInfo?.lng}" target="_blank">${createComplainModalData?.vehicleInfo?.lat},${createComplainModalData?.vehicleInfo?.lng}</a>
        </p>
      </div>`,
      });

      markerInstance.addListener("click", () => {
        infoWindow.open(mapRef.current.map, markerInstance);
      });
    };
  }, [createComplainModalData, props.google]);

  // if (!props.google) {
  //   return <div>Error: Google Maps API failed to load.</div>;
  // }

  if (!props.google || !createComplainModalData || !createComplainModalData.vehicleInfo) {
    return (
      <div>Error: Google Maps API failed to load or no data available.</div>
    );
  }

  return (
    <div className="map-container">
      <Map
        google={props.google}
        zoom={14}
        initialCenter={{
          lat: parseFloat(createComplainModalData?.vehicleInfo?.lat),
          lng: parseFloat(createComplainModalData?.vehicleInfo?.lng),
        }}
        disableDefaultUI={false} // Enable default UI controls
        style={{ height: "88%", width: "97%", overflow: "unset" }}
        ref={mapRef} // Set ref for the map
      ></Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyDI3fB-ItM1LpMOyMg_P8UxTUuxHCyQS34",
})(Location);

// ------------------------------------------

// if (createComplainModalData.alertEvent.course < 90) {
//   context.rotate((createComplainModalData.alertEvent.course * Math.PI) / 90);
// } else if (createComplainModalData.alertEvent.course < 180) {
//   context.rotate((createComplainModalData.alertEvent.course * Math.PI) / -360);
// } else if (createComplainModalData.alertEvent.course < 270) {
//   context.rotate((createComplainModalData.alertEvent.course * Math.PI) / -360);
// } else {
//   context.rotate((createComplainModalData.alertEvent.course * Math.PI) / 360);
// }
