import { useState } from "react";
import Logo from "./Logo";
import { H3, P } from "../../../AbstractElements";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { EmailAddress, Password } from "../../../utils/Constant";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setActiveCRMModules } from "../../../ReaduxToolkit/Reducer/CRMModuleSlice";
import Swal from "sweetalert2";
import { setUserPersonalizationData } from "../../../ReaduxToolkit/Reducer/PersonalizationSlice";
import { setUserPermissions } from "../../../ReaduxToolkit/Reducer/PermissionSlice";
import { ROLES } from "../../../constants/roles";
import {
  setLoginData,
  setUserData,
} from "../../../ReaduxToolkit/Reducer/UserSlice";
import { setDevices } from "../../../ReaduxToolkit/Reducer/MonitoringSlice";
import { getAll, login } from "../../../Utilities/api";
import { getOperatorCompanyUserId, getUserRole } from "../../../Utilities/globals/globals";
import { GET_ALLOWED_MODULES, GET_ALLOWED_PERMISSIONS, GET_ALL_ACTIVE_CRM_MODULES, GET_COMPANY_PERSONALIZATION, GET_SUPERADMIN_PERSONALIZATION, GET_USER_BY_ID } from "../../../Utilities/api/apiEndpoints";

interface loginTypes {
  emailAddress: string; // Change 'boolean' to the actual type of isVisible
  password: string;
}

export interface CommonFormPropsType {
  alignLogo?: string;
}
const CommonForm = ({ alignLogo }: CommonFormPropsType) => {
  const { userPersonalizationData } = useSelector(
    (state: any) => state.personalization
  );
  const [showPassWord, setShowPassWord] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const header = "Sign In ";

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<loginTypes>();

  const loginData: SubmitHandler<loginTypes> = async (data, e: any) => {
    e.preventDefault();
    if (data.emailAddress !== "" && data.password !== "") {
      const loginData = {
        emailAddress: data.emailAddress,
        password: data.password,
      };
      setLoading(true);
      // console.log(loginData);
      try {
        await login(loginData).then(async (data: any) => {
          // console.log("response: ", data);

          setLoading(false);
          // if (data == undefined) {
          //   Swal.fire({
          //     text: "Connection Refused or not connected",
          //     icon: "error",
          //     timer: 2000,
          //     showConfirmButton: false,
          //   });
          //   setLoading(false);
          //   return;
          // }

          if (!data.success && data.statusCode !== 401) {
            Swal.fire({
              text: `${data.message}`,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          }

          if (data.statusCode === 401) {
            if (data.data) {
              if (data.data.isOtpEnabled) {
                // console.log("response: ", data.data);

                localStorage.setItem("userId", data.data.userId);
                navigate(`${process.env.PUBLIC_URL}/verifyOTP`);
                // localStorage.setItem("userId", data.data.userId);
              }
            } else {
              Swal.fire({
                text: `${data.message}`,
                icon: "error",
                timer: 2000,
                showCancelButton: false,
              });
            }
          } else if (data.statusCode === 200) {
            dispatch(setLoginData(data));
            dispatch(setUserData(null));
            const token = data.data.authToken;
            // console.log("response: ", data.data);
            // console.log("token: ", token);

            localStorage.setItem("token", token);

            if (
              getUserRole() == ROLES.COMPANY ||
              getUserRole() == ROLES.OPERATOR
            ) {
              dispatch(setDevices([]));
            }
            if (getUserRole() === ROLES.SUPER_ADMIN) {
              dispatch(setUserPermissions([]));
            }

            if (getUserRole() === ROLES.OPERATOR) {
              localStorage.setItem("userId", getOperatorCompanyUserId());
            } else if (getUserRole() === ROLES.COMPANY) {
              localStorage.setItem("userId", data.data.userId);
            } else if (getUserRole() === ROLES.SUPER_ADMIN) {
              localStorage.removeItem("userId");
            }
            const userId = localStorage.getItem("userId");
            getAll({
              url:
                getUserRole() === ROLES.COMPANY ||
                  getUserRole() === ROLES.OPERATOR
                  ? GET_ALLOWED_MODULES
                  : GET_ALL_ACTIVE_CRM_MODULES,
            }).then((data: any) => {
              // console.log("active Modules --- >", data);

              if (data !== undefined) {
                if (!data.success) {
                  // console.log("hhg");
                  // console.log(userId);
                  dispatch(setActiveCRMModules([]));
                }
                if (data.success) {
                  // console.log("hhg");
                  // console.log(userId);
                  dispatch(setActiveCRMModules(data.data));
                }
              }
            });
            getAll({
              url:
                userId == null && userId == undefined
                  ? GET_SUPERADMIN_PERSONALIZATION
                  : `${GET_COMPANY_PERSONALIZATION}/${userId}`,

              // url: GET_SUPERADMIN_PERSONALIZATION,
            }).then((parentData: any) => {
              // console.log("api call --- >", parentData);
              if (parentData == undefined) {
                // Swal.fire({
                //   text: `${parentData.message}`,
                //   icon: "error",
                //   timer: 2000,
                //   showConfirmButton: false,
                // });
                setLoading(false);
                dispatch(setUserPersonalizationData(null));
              }

              if (parentData !== undefined) {
                if (!parentData.success) {
                  setLoading(false);
                  dispatch(setUserPersonalizationData(null));
                }
                if (parentData.success) {
                  dispatch(setUserPersonalizationData(parentData.data));
                }
              }
            });
            dispatch(setUserData(null));
            getAll({ url: GET_USER_BY_ID }).then((parentData: any) => {
              if (parentData !== undefined) {
                if (parentData.success) {
                  dispatch(setUserData(parentData.data.user));
                }
              }
            });

            if (
              getUserRole() === ROLES.COMPANY ||
              getUserRole() === ROLES.OPERATOR
            ) {
              getAll({ url: GET_ALLOWED_PERMISSIONS })
                .then((data: any) => {
                  // console.log("api call --- >", data);
                  if (data !== undefined) {
                    if (data.success) {
                      // console.log("hhg");
                      dispatch(
                        setUserPermissions(data.data.allowedPermissions)
                      );
                      setLoading(false);
                      navigate(`${process.env.PUBLIC_URL}/dashboard`);
                    }
                    if (!data.success) {
                      navigate(`${process.env.PUBLIC_URL}/dashboard`);
                    }
                  }
                })
                .catch((error: any) => {
                  navigate(`${process.env.PUBLIC_URL}/dashboard`);
                });
            } else {
              navigate(`${process.env.PUBLIC_URL}/dashboard`);
            }
          }

          reset({
            // emailAddress: "",
            password: "",
          });
        });

        // Handle successful post creation (e.g., show a success message, redirect, etc.)
      } catch (error: any) {
        // Handle error (e.g., show an error message)
        // console.error("Error creating post:", error);
        setLoading(false);
        // if (error.response.statusCode === 401) {
        //     errorAlert();
        //   }
      }
    }
  };
  return (
    <div className="login-card login-dark">
      <div>
        {/* <div>
          <Logo alignLogo={alignLogo} />
        </div> */}
        <div className="login-main">
          <Form className="theme-form" onSubmit={handleSubmit(loginData)}>
            <H3>{header}</H3>
            <P>{"Enter your email & password to login"}</P>
            <FormGroup>
              <Label className="col-form-label">{EmailAddress}</Label>
              <input
                className="form-control"
                type="email"
                required
                placeholder="Test@gmail.com"
                {...register("emailAddress", { required: true })}
              />

              <span style={{ color: "red" }}>
                {errors.emailAddress && "Email is required"}
              </span>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label">{Password}</Label>
              <div className="form-input position-relative">
                <input
                  //     style={{
                  //       backgroundColor: `${userPersonalizationData &&
                  //         userPersonalizationData.buttonsAndBarsColor !== "" && userPersonalizationData.buttonsAndBarsColor
                  //         }

                  // `,

                  // }}
                  className="form-control"
                  type={showPassWord ? "text" : "password"}
                  placeholder="*********"
                  required
                  {...register("password", { required: true })}
                />

                <div className="show-hide">
                  <span
                    style={{
                      color: `${userPersonalizationData &&
                        userPersonalizationData.buttonsAndBarsColor !== "" && userPersonalizationData.buttonsAndBarsColor
                        }
              `,
                    }}
                    onClick={() => setShowPassWord(!showPassWord)}
                    className={!showPassWord ? "show" : ""}
                  />
                </div>
              </div>
              <span style={{ color: "red" }}>
                {errors.password && "Paswword is required"}
              </span>
            </FormGroup>
            <FormGroup className="mb-0 form-group">
              {/* <Link
                className="link"
                to={`${process.env.PUBLIC_URL}/pages/authentication/forgetpassword`}
              >
                {ForgotPassword}
              </Link> */}
              <div className="text-end mt-2">
                {/* <Button
                  disabled={loading}
                  color="primary"
                  className="btn-block w-100"
                >
                  {loading ? "Loading..." : "Sign In"}
                </Button> */}
                {userPersonalizationData &&
                  userPersonalizationData.buttonsAndBarsColor !== "" ? (
                  <button
                    disabled={loading}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: `${userPersonalizationData.buttonsAndBarsColor
                        }
                  `,
                      padding: "10px 10px",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      border: `${userPersonalizationData &&
                        userPersonalizationData.buttonsAndBarsColor
                        }
                  `,
                    }}
                    className="btn-block w-100"
                  >
                    {loading ? "Loading..." : "Sign In"}
                  </button>
                ) : (
                  <Button
                    disabled={loading}
                    color="primary"
                    className="btn-block w-100"
                  >
                    {loading ? "Loading..." : "Sign In"}
                  </Button>
                )}
              </div>
            </FormGroup>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CommonForm;
